import axios from 'axios';
import dayjs from 'dayjs';
import firebase from 'firebase';
import {useMutation} from 'react-query';

const axiosConfig = {
  baseURL: '/twirp',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*',
  },
};

const beerClient = axios.create(axiosConfig);

interface ImportSpotListingsResponse {
  spot_listings: SpotListing[];
  existing_spot_listings: SpotListing[];
  errors: string[];
}

interface SpotListing {
  id: string;
  name: string;
  start_time: Date;
  end_time: Date;
  fee: number;
  available: boolean;
  description: string;
  notes: string;
  clinical_departments_texts: string[];
  carfare_text: string;
  hospital: Hospital;
}

interface Hospital {
  id: string;
  general_hospital_id: string;
  name: string;
  website_url: string;
  address: string;
  access: string;
}

interface ErrorMessage {
  id: number;
  message: string;
}

export const useImportSpotListingApi = () => {
  const url
    = '/drsprime.beerserver.listing.admin.AdminListingService/ImportSpotListings';

  return useMutation('posts', async (dryRun: boolean) => {
    const token = await firebase.auth().currentUser?.getIdToken();
    const {data} = await beerClient.request<ImportSpotListingsResponse>({
      url,
      method: 'POST',
      data: {dry_run: dryRun},
      headers: {Authorization: `Bearer ${token ?? ''}`},
    });

    const errorMessages: ErrorMessage[] = data.errors.map((message: string, i: number) => ({id: i, message}));

    return {
      errors: errorMessages,
      existing_spot_listings: data.existing_spot_listings.map((d: SpotListing, i: number) => ({
        id: i + 1,
        name: d.hospital.name,
        start_time: dayjs(d.start_time).format('YYYY-MM-DDTHH:mm'),
        end_time: dayjs(d.end_time).format('YYYY-MM-DDTHH:mm'),
        fee: d.fee,
        clinical_departments_texts: d.clinical_departments_texts.join(','),
        carfare_text: d.carfare_text,
        description: d.description,
        notes: d.notes,
      })),
      spot_listings: data.spot_listings.map((d: SpotListing, i: number) => ({
        id: i + 1,
        name: d.hospital.name,
        start_time: dayjs(d.start_time).format('YYYY-MM-DDTHH:mm'),
        end_time: dayjs(d.end_time).format('YYYY-MM-DDTHH:mm'),
        fee: d.fee,
        clinical_departments_texts: d.clinical_departments_texts.join(','),
        carfare_text: d.carfare_text,
        description: d.description,
        notes: d.notes,
      })),
    };
  });
};
