import axios from 'axios';
import firebase from 'firebase';
import {useMutation} from 'react-query';

const axiosConfig = {
  baseURL: '/twirp',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*',
  },
};

const beerClient = axios.create(axiosConfig);

interface ImportHospitalsAndHospitalStaffsResponse {
  hospitals: Hospital[];
  existing_hospitals: Hospital[];
  update_hospitals: HospitalDiff[];
  hospital_staffs: HospitalStaff[];
  existing_hospital_staffs: HospitalStaff[];
  update_hospital_staffs: HospitalStaffDiff[];
  errors: string[];
}

interface Hospital {
  id: string;
  general_hospital_id: string;
  name: string;
  website_url: string;
  postal_code: string;
  address: string;
  access: string;
  attractive_category: number;
  facility_category: number;
  medical_institution_id: string;
  prefecture: number;
  telephone: string;
  fax: string;
}

interface HospitalStaff {
  id: string;
  general_hospital_id: string;
  last_name: string;
  first_name: string;
  email: string;
}

export interface HospitalDiff {
  before: Hospital;
  after: Hospital;
}

export interface HospitalStaffDiff {
  before: HospitalStaff;
  after: HospitalStaff;
}

interface ErrorMessage {
  id: number;
  message: string;
}

export interface ImportHospitalsAndHospitalStaffsTableContents {
  hospitals: Hospital[];
  existing_hospitals: Hospital[];
  update_hospitals: HospitalDiff[];
  hospital_staffs: HospitalStaff[];
  existing_hospital_staffs: HospitalStaff[];
  update_hospital_staffs: HospitalStaffDiff[];
  errors: ErrorMessage[];
}

export const useImportHospitalAndHospitalStaffApi = () => {
  const url
    = '/drsprime.beerserver.admin.hospital.AdminHospitalService/ImportHospitalsAndHospitalStaffs';

  return useMutation('posts', async (dryRun: boolean) => {
    const token = await firebase.auth().currentUser?.getIdToken();
    const {data} = await beerClient.request<ImportHospitalsAndHospitalStaffsResponse>({
      url,
      method: 'POST',
      data: {dry_run: dryRun},
      headers: {Authorization: `Bearer ${token ?? ''}`},
    });
    data.hospital_staffs = data.hospital_staffs.map((h, i) => {
      h.id = `${i + 1}`;
      return h;
    });

    const errorMessages: ErrorMessage[] = data.errors.map((message: string, i: number) => ({id: i, message}));

    const response: ImportHospitalsAndHospitalStaffsTableContents = {
      errors: errorMessages,
      hospitals: data.hospitals,
      existing_hospitals: data.existing_hospitals,
      update_hospitals: data.update_hospitals,
      hospital_staffs: data.hospital_staffs,
      existing_hospital_staffs: data.existing_hospital_staffs,
      update_hospital_staffs: data.update_hospital_staffs,
    };

    return response;
  });
};
