import React from 'react';
import {Backdrop, CircularProgress} from '@mui/material';

type Props = {
  isLoading: boolean;
};

const LoadingBackdrop: React.VFC<Props> = ({isLoading}: Props) => (
  <Backdrop
    sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
    open={isLoading}
  >
    <CircularProgress color="inherit"/>
  </Backdrop>
);

export default LoadingBackdrop;
