import React, {useState} from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stepper,
  Step,
  StepLabel,
  Toolbar,
} from '@mui/material';

import {SpotListingSheetValidator, SpotListingSheetImporter} from '../components/sheetImport/spotListing';
import {HospitalSheetValidator, HospitalSheetImporter} from '../components/sheetImport/hospital';
import {ImportSheet} from '../components/sheetImport/types';
import {RegularListingSheetImporter, RegularListingSheetValidator} from '../components/sheetImport/regularListing';

const getSteps = () => ['データの選択', 'チェック', '確認'];

type StepContentProps = {
  stepIndex: number;
  sheetType: ImportSheet;
  handleChange: (event: SelectChangeEvent<ImportSheet>) => void;
  handleBack: () => void;
  handleNext: () => void;
  backToTop: () => void;
};

const getStepContent = ({
  stepIndex, sheetType, handleChange, handleBack, handleNext, backToTop,
}: StepContentProps) => {
  if (sheetType === 'SpotListing') {
    switch (stepIndex) {
      case 0:
        return (
          <ImportSelector
            sheetType={sheetType}
            handleChange={handleChange}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <SpotListingSheetValidator
            sheetType={sheetType}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <SpotListingSheetImporter
            sheetType={sheetType}
            handleBack={handleBack}
            handleNext={backToTop}
          />
        );
      default:
        return (
          <span>
            step: {stepIndex} 選択シート: {sheetType}
          </span>
        );
    }
  }

  if (sheetType === 'RegularListing') {
    switch (stepIndex) {
      case 0:
        return (
          <ImportSelector
            sheetType={sheetType}
            handleChange={handleChange}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 1:
        return (
          <RegularListingSheetValidator
            sheetType={sheetType}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
      case 2:
        return (
          <RegularListingSheetImporter
            sheetType={sheetType}
            handleBack={handleBack}
            handleNext={backToTop}
          />
        );
      default:
        return (
          <span>
            step: {stepIndex} 選択シート: {sheetType}
          </span>
        );
    }
  }

  switch (stepIndex) {
    case 0:
      return (
        <ImportSelector
          sheetType={sheetType}
          handleChange={handleChange}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      );
    case 1:
      return (
        <HospitalSheetValidator
          sheetType={sheetType}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      );
    case 2:
      return (
        <HospitalSheetImporter
          sheetType={sheetType}
          handleBack={handleBack}
          handleNext={backToTop}
        />
      );
    default:
      return (
        <span>
          step: {stepIndex} 選択シート: {sheetType}
        </span>
      );
  }
};

interface ImportSelectorProps {
  sheetType: ImportSheet;
  handleChange: (event: SelectChangeEvent<ImportSheet>) => void;
  handleBack: () => void;
  handleNext: () => void;
}
const ImportSelector: React.VFC<ImportSelectorProps> = ({
  sheetType,
  handleChange,
  handleBack,
  handleNext,
}: ImportSelectorProps) => {
  const isError = sheetType === undefined;
  return (
    <>
      <Box sx={{marginBottom: theme => theme.spacing(2)}}>
        <p>インポート対象のシートを選択してください。</p>
        <FormControl
          required
          sx={{
            margin: theme => theme.spacing(1),
            minWidth: 120,
          }}
        >
          <InputLabel id="importSelect">シート</InputLabel>
          <Select value={sheetType} onChange={handleChange}>
            <MenuItem value="SpotListing">SpotListing</MenuItem>
            <MenuItem value="RegularListing">RegularListing</MenuItem>
            <MenuItem value="Hospital">Hospital</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <div>
        <Button disabled sx={{marginRight: theme => theme.spacing(2)}} onClick={handleBack}>
          戻る
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={isError}
          onClick={handleNext}
        >
          チェック
        </Button>
      </div>
    </>
  );
};

export const DataUploader: React.VFC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [sheetType, setSheetType] = useState<ImportSheet>();
  const steps = getSteps();

  const handleChange = (event: SelectChangeEvent<ImportSheet>) => {
    setSheetType(event.target.value as ImportSheet);
  };

  const handleBack = () => {
    setActiveStep(previous => previous - 1);
  };

  const handleNext = () => {
    setActiveStep(previous => previous + 1);
  };

  const backToTop = () => {
    setActiveStep(0);
  };

  const content = getStepContent({
    stepIndex: activeStep,
    sheetType,
    handleChange,
    handleBack,
    handleNext,
    backToTop,
  });
  return (
    <>
      <Toolbar>DataUploader</Toolbar>
      <Divider/>
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: theme => theme.spacing(4),
          paddingBottom: theme => theme.spacing(4),
        }}
      >
        <Stepper activeStep={activeStep}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box
          sx={{
            marginBottom: theme => theme.spacing(2),
          }}
        >
          {content}
        </Box>
      </Container>
    </>
  );
};
