import React from 'react';
import {useNavigate} from 'react-router-dom';
import {styled} from '@mui/material/styles';

import {
  AppBar as MuiAppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import {Menu} from '@mui/icons-material';

import {useAuth} from '../authProvider';

const AppBar = styled(MuiAppBar)(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

type Props = {
  onClick: () => void;
};

export const AppBarTop: React.FC<Props> = ({onClick}: Props) => {
  const navigate = useNavigate();
  const {logout, loginUser} = useAuth();
  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton
          aria-label="menu"
          color="inherit"
          edge="start"
          size="large"
          onClick={onClick}
        >
          <Menu/>
        </IconButton>
        <Typography variant="h6" sx={{flexGrow: 1}}>
          Dr&apos;s Prime Admin Console
        </Typography>
        {loginUser ? (
          <Button
            color="inherit"
            onClick={() => {
              logout();
            }}
          >
            {loginUser.displayName}
          </Button>
        ) : (
          <Button
            color="inherit"
            onClick={() => {
              navigate('/login');
            }}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
