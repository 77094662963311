import React from 'react';
import {Navigate} from 'react-router';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {Container} from '@mui/material';

import {useAuth} from '../authProvider';

export const Login: React.VFC = () => {
  const {login, loginUser} = useAuth();
  const uiConfig: firebaseui.auth.Config = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    callbacks: {
      signInSuccessWithAuthResult: (authResult: {
        user: firebase.User;
        credential: {accessToken: string};
      }) => {
        const user = authResult.user;
        const token = authResult.credential.accessToken;
        login(user, token);
        return false;
      },
    },
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        scopes: ['https://www.googleapis.com/auth/drive.readonly'],
        customParameters: {
          prompt: 'select_account',
          hd: 'drsprime.com',
        },
      },
    ],
  };

  if (loginUser) {
    return <Navigate replace to="/"/>;
  }

  return (
    <Container maxWidth="lg">
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
      />
    </Container>
  );
};
