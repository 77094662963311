import React from 'react';
import {DataGrid, GridColDef, GridRowModel} from '@mui/x-data-grid';
import {
  Typography,
  SxProps,
} from '@mui/material';

type ImportTableProps = {
  rows: GridRowModel[];
  columns: GridColDef[];
  title: string;
  titleColor?:
  | 'inherit'
  | 'initial'
  | 'textPrimary'
  | 'primary'
  | 'secondary'
  | 'textSecondary'
  | 'error';
  notes?: string;
  pageSize: number;
  sx?: SxProps;
};

export const ImportTable: React.FC<ImportTableProps> = props => {
  const {rows, columns, title, titleColor, notes, pageSize, sx} = props;
  return (
    <>
      <Typography color={titleColor ?? 'textPrimary'}>
        <h3>{title}</h3>
        {notes && (
          <p>{notes}</p>
        )}
      </Typography>
      <div style={{width: '100%'}}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          sx={sx}
        />
      </div>
    </>
  );
};
