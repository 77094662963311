import React, {useState, useEffect} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
} from '@mui/material';
import {green, red} from '@mui/material/colors';

import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid';
import {
  useImportHospitalAndHospitalStaffApi,
  ImportHospitalsAndHospitalStaffsTableContents,
  HospitalDiff,
  HospitalStaffDiff,
} from '../../api/useImportHospitalApi';
import LoadingBackdrop from '../Backdrop';
import {SheetImporterProps} from './types';
import {ImportTable} from './Table';

const beforeColor = red[100];
const afterColor = green[100];

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert ref={ref} elevation={6} variant="filled" {...props}/>
));

const hospitalColumns: GridColDef[] = [
  {field: 'id', headerName: 'ID', width: 300},
  {field: 'name', headerName: '病院名', width: 300},
  {field: 'postal_code', headerName: '郵便番号', width: 200},
  {field: 'address', headerName: '住所', width: 300},
  {field: 'access', headerName: 'アクセス', width: 200},
  {field: 'website_url', headerName: 'URL', width: 300},
  {
    field: 'attractive_category',
    headerName: 'attractive_category',
    width: 150,
  },
  {
    field: 'facility_category',
    headerName: '施設タイプ',
    width: 150,
  },
  {
    field: 'medical_institution_id',
    headerName: '医療施設番号',
    width: 300,
  },
  {field: 'prefecture', headerName: '都道府県', width: 150},
  {field: 'telephone', headerName: '電話番号', width: 150},
  {field: 'fax', headerName: 'FAX', width: 150},
];

const hospitalUpdateColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 300,
  },
  {
    field: 'name',
    headerName: '病院名',
    width: 300,
    renderCell: (
      parameters: GridRenderCellParams<{before: string; after: string}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'postal_code',
    headerName: '郵便番号',
    width: 300,
    renderCell: (
      parameters: GridRenderCellParams<{before: string; after: string}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'address',
    headerName: '住所',
    width: 300,
    renderCell: (
      parameters: GridRenderCellParams<{before: string; after: string}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'access',
    headerName: 'アクセス',
    width: 200,
    renderCell: (
      parameters: GridRenderCellParams<{before: string; after: string}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'website_url',
    headerName: 'URL',
    width: 300,
    renderCell: (
      parameters: GridRenderCellParams<{before: string; after: string}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'attractive_category',
    headerName: 'attractive_category',
    width: 150,
    renderCell: (
      parameters: GridRenderCellParams<{before: number; after: number}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'facility_category',
    headerName: 'facility_category',
    width: 150,
    renderCell: (
      parameters: GridRenderCellParams<{before: number; after: number}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'medical_institution_id',
    headerName: 'medical_institution_id',
    width: 300,
    renderCell: (
      parameters: GridRenderCellParams<{before: number; after: number}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'prefecture',
    headerName: 'prefecture',
    width: 150,
    renderCell: (
      parameters: GridRenderCellParams<{before: number; after: number}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'telephone',
    headerName: 'telephone',
    width: 150,
    renderCell: (
      parameters: GridRenderCellParams<{before: number; after: number}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'fax',
    headerName: 'fax',
    width: 150,
    renderCell: (
      parameters: GridRenderCellParams<{before: number; after: number}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
];

const convertHospitalDiff = (diff: HospitalDiff) => {
  const {before, after} = diff;
  return {
    id: after.id,
    name: {before: before.name, after: after.name},
    postal_code: {before: before.postal_code, after: after.postal_code},
    address: {before: before.address, after: after.address},
    access: {before: before.access, after: after.access},
    website_url: {before: before.website_url, after: after.website_url},
    attractive_category: {before: before.attractive_category, after: after.attractive_category},
    facility_category: {before: before.facility_category, after: after.facility_category},
    general_hospital_id: {before: before.general_hospital_id, after: after.general_hospital_id},
    medical_institution_id: {before: before.medical_institution_id, after: after.medical_institution_id},
    prefecture: {before: before.prefecture, after: after.prefecture},
    telephone: {before: before.telephone, after: after.telephone},
    fax: {before: before.fax, after: after.fax},
  };
};

const convertHospitalStaffDiff = (diff: HospitalStaffDiff) => {
  const {before, after} = diff;
  return {
    id: {before: before.id, after: after.id},
    last_name: {before: before.last_name, after: after.last_name},
    first_name: {before: before.first_name, after: after.first_name},
    email: {before: before.email, after: after.email},
    general_hospital_id: {
      before: before.general_hospital_id,
      after: after.general_hospital_id,
    },
  };
};

const hospitalStaffColumns: GridColDef[] = [
  {field: 'id', headerName: 'ID', width: 300},
  {field: 'last_name', headerName: '名字', width: 120},
  {field: 'first_name', headerName: '名前', width: 150},
  {field: 'email', headerName: 'メール', width: 500},
];

const hospitalStaffUpdateColumns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    width: 300,
    renderCell: (
      parameters: GridRenderCellParams<{before: string; after: string}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'last_name',
    headerName: '名字',
    width: 120,
    renderCell: (
      parameters: GridRenderCellParams<{before: string; after: string}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'first_name',
    headerName: '名前',
    width: 150,
    renderCell: (
      parameters: GridRenderCellParams<{before: string; after: string}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
  {
    field: 'email',
    headerName: 'メール',
    width: 500,
    renderCell: (
      parameters: GridRenderCellParams<{before: string; after: string}>,
    ) => {
      if (parameters.value.before === parameters.value.after) {
        return parameters.value.after;
      }

      return (
        <>
          <Box sx={{backgroundColor: beforeColor, px: 1}}>
            {parameters.value.before}
          </Box>
          →
          <Box sx={{backgroundColor: afterColor, px: 1}}>
            {parameters.value.after}
          </Box>
        </>
      );
    },
  },
];

const errorColumns: GridColDef[] = [
  {field: 'id', headerName: '番号', minWidth: 50},
  {field: 'message', headerName: 'エラー', minWidth: 500},
];

const HospitalImportTable: React.FC<ImportHospitalsAndHospitalStaffsTableContents> = props => {
  const {
    errors,
    hospitals,
    hospital_staffs,
    existing_hospitals,
    existing_hospital_staffs,
    update_hospitals,
    update_hospital_staffs,
  } = props;
  return (
    <>
      {errors.length > 0 && (
        <>
          <ImportTable
            rows={errors}
            columns={errorColumns}
            title="エラーが発生しています"
            titleColor="error"
            pageSize={10}
            sx={{
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
              },
            }}
          />
          <Divider/>
        </>
      )}
      {existing_hospitals.length > 0 && (
        <ImportTable
          rows={existing_hospitals}
          columns={hospitalColumns}
          title="重複している病院"
          titleColor="error"
          notes="general_hospital_id、または病院名が重複している病院が存在します"
          pageSize={30}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
            },
          }}
        />
      )}
      {existing_hospital_staffs.length > 0 && (
        <ImportTable
          rows={existing_hospital_staffs}
          columns={hospitalStaffColumns}
          title="重複している病院担当者"
          titleColor="error"
          notes="同じメールアドレスで登録されている担当者が存在します"
          pageSize={30}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
            },
          }}
        />
      )}
      {hospitals.length > 0 && (
        <ImportTable
          rows={hospitals}
          columns={hospitalColumns}
          title="追加対象の病院"
          pageSize={30}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
            },
          }}
        />
      )}
      {update_hospitals.length > 0 && (
        <ImportTable
          rows={update_hospitals.map(uh => convertHospitalDiff(uh))}
          columns={hospitalUpdateColumns}
          title="更新対象の病院"
          pageSize={30}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
            },
          }}
        />
      )}
      {hospital_staffs.length > 0 && (
        <ImportTable
          rows={hospital_staffs}
          columns={hospitalStaffColumns}
          title="追加対象の病院担当者"
          pageSize={30}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
            },
          }}
        />
      )}
      {update_hospital_staffs.length > 0 && (
        <ImportTable
          rows={update_hospital_staffs.map(uh => convertHospitalStaffDiff(uh))}
          columns={hospitalStaffUpdateColumns}
          title="更新対象の病院担当者"
          pageSize={30}
          sx={{
            '& .MuiDataGrid-cell': {
              whiteSpace: 'normal',
            },
          }}
        />
      )}
    </>
  );
};

export const HospitalSheetValidator: React.VFC<SheetImporterProps> = ({
  handleBack,
  handleNext,
}: SheetImporterProps) => {
  const {
    data,
    mutateAsync: importHospital,
    isLoading,
  } = useImportHospitalAndHospitalStaffApi();

  useEffect(() => {
    const f = async () => {
      const dryRun = true;
      await importHospital(dryRun);
    };

    f().catch(error => {
      console.error(error);
    });
  }, [importHospital]);

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const skipValidation = !data?.errors.length
    && !data?.existing_hospitals.length
    && !data?.existing_hospital_staffs.length;

  return (
    <>
      <LoadingBackdrop isLoading={isLoading}/>
      <Box
        sx={{
          marginBottom: theme => theme.spacing(2),
        }}
      >
        {data && <HospitalImportTable {...data}/>}
      </Box>
      <div>
        <Button
          sx={{
            marginRight: theme => theme.spacing(1),
          }}
          onClick={handleBack}
        >
          戻る
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!skipValidation}
          onClick={handleOpenDialog}
        >
          確認
        </Button>
        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleCloseDialog}
        >
          <DialogTitle id="alert-dialog-title">
            スプレッドシートのインポートを実行しますか？
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              インポートを実行するとプロダクトにデータが反映されます。ユーザに公開されても問題ないデータであることを確認してください。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDialog}>
              キャンセル
            </Button>
            <Button autoFocus color="primary" onClick={handleNext}>
              実行する
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export const HospitalSheetImporter: React.VFC<SheetImporterProps> = ({
  handleBack,
  handleNext,
}: SheetImporterProps) => {
  const {
    data,
    mutateAsync: importHospital,
    isLoading,
  } = useImportHospitalAndHospitalStaffApi();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  useEffect(() => {
    const f = async () => {
      const dryRun = false;
      await importHospital(dryRun);
      setSnackbarOpen(true);
    };

    f().catch(error => {
      console.error(error);
    });
  }, [importHospital]);
  return (
    <>
      <LoadingBackdrop isLoading={isLoading}/>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      >
        <Alert
          severity="success"
          sx={{width: '100%'}}
          onClose={() => {
            setSnackbarOpen(false);
          }}
        >
          病院・病院担当者のインポートに成功しました
        </Alert>
      </Snackbar>
      <Box
        sx={{
          marginRight: theme => theme.spacing(2),
        }}
      >
        {data && <HospitalImportTable {...data}/>}
      </Box>
      <div>
        <Button
          sx={{
            marginRight: theme => theme.spacing(1),
          }}
          onClick={handleBack}
        >
          戻る
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Topへ
        </Button>
      </div>
    </>
  );
};
