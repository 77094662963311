import React, {useState, useEffect} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Snackbar,
  Typography,
} from '@mui/material';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {DataGrid, GridColDef} from '@mui/x-data-grid';

import LoadingBackdrop from '../Backdrop';

import {useImportRegularListingApi} from '../../api/useImportRegularListingApi';
import {SheetImporterProps} from './types';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert ref={ref} elevation={6} variant="filled" {...props}/>
));

const regularListingColumns: GridColDef[] = [
  {field: 'id', headerName: '番号', width: 50},
  {field: 'name', headerName: '病院名', width: 300},
  {field: 'start_text', headerName: '勤務開始', width: 200},
  {field: 'end_text', headerName: '勤務終了', width: 200},
  {field: 'fee', headerName: '給与', width: 120},
  {field: 'clinical_departments_text', headerName: '診療科目', width: 150},
  {field: 'carfare_text', headerName: '交通費', width: 150},
  {field: 'description', headerName: '詳細', width: 500},
  {field: 'notes', headerName: '備考', width: 300},
];

export const RegularListingSheetValidator: React.VFC<SheetImporterProps> = ({
  handleBack,
  handleNext,
}: SheetImporterProps) => {
  const {data, mutateAsync: importListing, isLoading} = useImportRegularListingApi();
  useEffect(() => {
    const f = async () => {
      const dryRun = true;
      await importListing(dryRun);
    };

    f().catch(error => {
      console.error(error);
    });
  }, [importListing]);

  const skipValidation
    = !data?.errors.length
    && !data?.existing_regular_listings.length;

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <LoadingBackdrop isLoading={isLoading}/>
      <Box
        sx={{
          marginRight: theme => theme.spacing(2),
        }}
      >
        {data?.errors && data?.errors.length > 0 && (
          <>
            <Typography color="error">
              <h3>エラーが発生しています</h3>
            </Typography>
            <div style={{width: '100%'}}>
              <DataGrid
                autoHeight
                rows={data.errors}
                columns={
                  [
                    {field: 'id', headerName: '番号', minWidth: 50},
                    {field: 'message', headerName: 'エラー', minWidth: 500},
                  ] as GridColDef[]
                }
                pageSize={10}
                sx={{
                  '& .MuiDataGrid-cell': {
                    whiteSpace: 'normal',
                  },
                }}
              />
            </div>
            <Divider/>
          </>
        )}
        {data?.existing_regular_listings && data?.existing_regular_listings.length > 0 && (
          <>
            <Typography color="error">
              <h3>重複している求人</h3>
            </Typography>
            <div style={{width: '100%'}}>
              <DataGrid
                autoHeight
                rows={data?.existing_regular_listings ?? []}
                columns={regularListingColumns}
                pageSize={10}
                sx={{
                  '& .MuiDataGrid-cell': {
                    whiteSpace: 'normal',
                  },
                }}
              />
            </div>
            <Divider/>
          </>
        )}
        <Typography color="textPrimary">
          <h3>追加される求人</h3>
        </Typography>
        <div style={{width: '100%'}}>
          <DataGrid
            autoHeight
            rows={data?.regular_listings ?? []}
            columns={regularListingColumns}
            pageSize={30}
            sx={{
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
              },
            }}
          />
        </div>
      </Box>
      <div>
        <Button
          sx={{
            marginRight: theme => theme.spacing(1),
          }}
          onClick={handleBack}
        >
          戻る
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!skipValidation}
          onClick={handleOpenDialog}
        >
          確認
        </Button>
        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={handleCloseDialog}
        >
          <DialogTitle id="alert-dialog-title">
            スプレッドシートのインポートを実行しますか？
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              インポートを実行するとプロダクトにデータが反映されます。ユーザに公開されても問題ないデータであることを確認してください。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDialog}>
              キャンセル
            </Button>
            <Button autoFocus color="primary" onClick={handleNext}>
              実行する
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};

export const RegularListingSheetImporter: React.VFC<SheetImporterProps> = ({
  handleBack,
  handleNext,
}: SheetImporterProps) => {
  const {data, mutateAsync: importListing, isLoading} = useImportRegularListingApi();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  useEffect(() => {
    const f = async () => {
      const dryRun = false;
      await importListing(dryRun);
      setSnackbarOpen(true);
    };

    f().catch(error => {
      console.error(error);
    });
  }, [importListing]);
  return (
    <>
      <LoadingBackdrop isLoading={isLoading}/>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarOpen(false);
        }}
      >
        <Alert
          severity="success"
          sx={{width: '100%'}}
          onClose={() => {
            setSnackbarOpen(false);
          }}
        >
          求人のインポートに成功しました
        </Alert>
      </Snackbar>
      <Box
        sx={{
          marginRight: theme => theme.spacing(2),
        }}
      >
        <Typography color="textPrimary">
          <h3>追加された求人</h3>
        </Typography>
        <div style={{width: '100%'}}>
          <DataGrid
            autoHeight
            rows={data?.regular_listings ?? []}
            columns={regularListingColumns}
            pageSize={30}
            sx={{
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal',
              },
            }}
          />
        </div>
      </Box>
      <div>
        <Button
          sx={{
            marginRight: theme => theme.spacing(1),
          }}
          onClick={handleBack}
        >
          戻る
        </Button>
        <Button variant="contained" color="primary" onClick={handleNext}>
          Topへ
        </Button>
      </div>
    </>
  );
};
