import React, {useEffect, useState} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {QueryClientProvider, QueryClient} from 'react-query';
import firebase from 'firebase';

import {styled, Theme, CSSObject} from '@mui/material/styles';

import {
  Box,
  CssBaseline,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import {ChevronLeft, Dashboard, Publish} from '@mui/icons-material';

import {AuthProvider, useAuth} from './authProvider';
import {AppBarTop} from './components/AppBar';
import LoadingBackdrop from './components/Backdrop';
import {DataUploader} from './pages/dataUpload';
import {Login} from './pages/Login';

const drawerWidth = 240;
const App = () => {
  const queryClient = new QueryClient();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const onClick = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Box sx={{display: 'flex'}}>
          <CssBaseline/>
          <AppBarTop onClick={onClick}/>
          <DrawerMenu drawerOpen={drawerOpen}/>
          <Main/>
        </Box>
      </QueryClientProvider>
    </AuthProvider>
  );
};

type PrivateRouteProps = {
  element: React.ReactElement;
  path: string;
};
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  element,
  path,
}: PrivateRouteProps) => {
  const {loginUser} = useAuth();
  return loginUser ? (
    <Route path={path} element={element}/>
  ) : (
    <Navigate to="/login"/>
  );
};

const Main: React.FC = () => {
  const {login} = useAuth();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const check = () => {
      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const token = await user.getIdToken();
          login(user, token);
        }

        setInitialized(true);
      });
    };

    check();
  }, [login]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      {/* <Box sx={theme => theme.mixins.toolbar} /> */}
      <DrawerHeader/>
      {initialized ? (
        <Routes>
          <Route path="/" element={<Box p={2}>HOME</Box>}/>
          <Route path="/login" element={<Login/>}/>
          <PrivateRoute path="/upload" element={<DataUploader/>}/>
          <Route path="*" element={<Navigate replace to="/"/>}/>
        </Routes>
      ) : (
        <LoadingBackdrop isLoading={!initialized}/>
      )}
    </Box>
  );
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
  ({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerMenu: React.FC<{drawerOpen: boolean}> = ({
  drawerOpen,
}: {
  drawerOpen: boolean;
}) => (
  <Drawer variant="permanent" open={drawerOpen}>
    <DrawerHeader>
      <IconButton size="large">
        <ChevronLeft/>
      </IconButton>
    </DrawerHeader>
    <Divider/>
    <List>
      <MainListItems/>
    </List>
    <Divider/>
    <List>
      <SecondaryListItems/>
    </List>
  </Drawer>
);

const MainListItems: React.VFC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <ListItem
        button onClick={() => {
          navigate('/');
        }}
      >
        <ListItemIcon>
          <Dashboard/>
        </ListItemIcon>
        <ListItemText primary="Dashboard"/>
      </ListItem>
    </div>
  );
};

const SecondaryListItems: React.VFC = () => {
  const navigate = useNavigate();
  return (
    <div>
      <ListSubheader inset>病院管理</ListSubheader>
      <ListItem
        button onClick={() => {
          navigate('/upload');
        }}
      >
        <ListItemIcon>
          <Publish/>
        </ListItemIcon>
        <ListItemText primary="Upload"/>
      </ListItem>
    </div>
  );
};

export default App;
