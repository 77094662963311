import React, {useContext, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import firebase from 'firebase';

console.log(process.env);
export const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
});

type ILoginUser = firebase.User | null;
export type AuthActionType = 'login' | 'logout';

type IAuthProvider = {
  login: (user: ILoginUser, token: string) => void;
  logout: () => void;
  loginUser: ILoginUser;
  accessToken: string;
};

export const AuthContext = React.createContext<IAuthProvider>(
  {} as IAuthProvider,
);

export const useAuth = () => useContext(AuthContext);

type Props = {
  children: React.ReactNode;
};
export const AuthProvider: React.FC<Props> = ({children}: Props) => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<ILoginUser>(null);
  const [accessToken, setAccessToken] = useState<string>('');

  const values = useMemo(() => {
    const login = (user: ILoginUser, token: string) => {
      setCurrentUser(user);
      setAccessToken(token);
      navigate('/');
    };

    const logout = async () => {
      await firebase.auth().signOut();
      setCurrentUser(null);
      setAccessToken('');
      navigate('/');
    };

    return {
      login,
      logout,
      loginUser: currentUser,
      accessToken,
    };
  }, [navigate, accessToken, currentUser]);

  return (
    <AuthContext.Provider
      value={values}
    >
      {children}
    </AuthContext.Provider>
  );
};
